
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

















































































































































$bp: l;

.flexible-timeline {
  &.can-scroll {
    overflow: hidden;
  }

  .wrapper {
    position: relative;
  }
}

.flexible-timeline__title {
  @extend %text-center;
}

.carousel.flexible-timeline__carousel {
  --scrollbar-width: 0;

  margin-top: var(--spacing-m);

  ::v-deep .carousel__scroller {
    overflow: visible;

    &.can-scroll {
      overflow-x: auto;
      padding-bottom: 10rem;
    }
  }

  ::v-deep .carousel__item {
    width: 29rem;
  }

  ::v-deep .carousel__controls {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    gap: $spacing * 0.5;
    margin-top: -6rem;
  }

  @include mq($bp) {
    --slide-gap: 4rem;

    ::v-deep .carousel__item {
      width: 58rem;
    }

    ::v-deep .carousel__controls {
      justify-content: flex-end;
      margin-top: -4rem;
    }
  }

  @media screen and (pointer: fine) {
    ::v-deep .carousel__controls {
      justify-content: flex-end;
      margin-top: -4rem;
    }
  }
}

.flexible-timeline__item__picture {
  @extend %border-radius;

  position: relative;
  width: 100%;
  aspect-ratio: 1;
  box-shadow: 0 2rem 8rem 0 rgba(0, 0, 0, 10%);

  img {
    @include image-fit;

    border-radius: inherit;
  }

  @include mq($bp) {
    aspect-ratio: 1.25;
  }
}

.flexible-timeline__item__content {
  @extend %border-radius-small;

  position: relative;
  z-index: 1;
  width: 100%;
  margin-top: -12rem;
  padding: var(--spacing-m);
  background: $c-white;
  box-shadow: 0 2rem 6rem 0 rgba(0, 0, 0, 10%);

  @include mq($bp) {
    width: calc(100% - calc(var(--spacing-s) * 2));
    margin: -14rem var(--spacing-s) 0;
  }
}

.flexible-timeline__item__content__year {
  @extend %fw-thin;

  color: $c-mustard;
  font-size: 2.5rem;
  font-style: italic;
}

.flexible-timeline__item__content__title {
  margin: 0.5rem 0;
  font-size: 1.8rem;

  @include mq(l) {
    margin: 0.5rem 0 1rem;
    font-size: 3rem;
  }
}

.flexible-timeline__progress {
  position: absolute;
  // right padding + 2 buttons + spacings
  right: calc(var(--wrapper-padding) + 13rem);
  bottom: 2.2rem;
  display: none;
  width: 10rem;
  height: 0.2rem;
  background: $c-medium-grey;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: $c-mustard;
    transform: scaleX(var(--progress));
    transform-origin: left;
    transition: transform 0.2s;
    will-change: transform;
  }

  @include mq(s) {
    width: 13.8rem;
  }

  @media screen and (pointer: fine) {
    display: block;
  }
}
